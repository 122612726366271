$(function () {
  $('.hamburger').on('click', function () {
    $(this).toggleClass('active');
    $('body').toggleClass('hidden');
    $('.navigation').toggleClass('active');
  });

  function roundAndCheckZeroes(number) {
    let precision = 6;
    let rounded = Number(number.toFixed(precision));

    while (rounded === 0) {
      precision++;
      rounded = Number(number.toFixed(precision));
    }

    return rounded;
  }

  function getApi() {
    $.ajax({
      url: '/api/v1/cryptocurrency/trending/latest',
      method: 'GET',
      timeout: 0,
      headers: {
        'X-CMC_PRO_API_KEY': '458f7600-e4fe-4417-813b-21cdb163b18c',
        Accept: '*/*',
      },
    }).done(function (response) {
      console.log(response);
      const arr = Array.from(response.data);

      for (const [index, item] of arr.entries()) {
        let curName = $(`
        <tr>
          <td>${index + 1}</td>
          <td>${item.symbol}</td>
          <td>${roundAndCheckZeroes(item.quote.USD.price)} $</td>
          <td>${
            item.quote.USD.percent_change_24h < 0
              ? '<span class="red">&#9660;</span>' + item.quote.USD.percent_change_24h.toFixed(3)
              : '<span>&#9650;</span>' + item.quote.USD.percent_change_24h.toFixed(3)
          } %</td>
          <td>${
            item.quote.USD.percent_change_30d < 0
              ? '<span class="red">&#9660;</span>' + item.quote.USD.percent_change_30d.toFixed(3)
              : '<span>&#9650;</span>' + item.quote.USD.percent_change_30d.toFixed(3)
          } %</td>
          <td>${
            item.quote.USD.percent_change_60d < 0
              ? '<span class="red">&#9660;</span>' + item.quote.USD.percent_change_60d.toFixed(3)
              : '<span>&#9650;</span>' + item.quote.USD.percent_change_60d.toFixed(3)
          } %</td>
        </tr>
      `);

        if (index > 29) {
          break;
        }

        $('.portfolio__table table tbody').append(curName);
      }

      $('.portfolio__table table').each(function () {
        var isCurrentPageSelected = sessionStorage.getItem('curPage') !== null;
        var currentPage = isCurrentPageSelected ? +sessionStorage.getItem('curPage') : 0;

        var numPerPage = 10;
        var $table = $(this);
        $table.bind('repaginate', function () {
          $table
            .find('tbody tr')
            .hide()
            .slice(currentPage * numPerPage, (currentPage + 1) * numPerPage)
            .show();
        });
        $table.trigger('repaginate');
        var numRows = $table.find('tbody tr').length;
        var numPages = Math.ceil(numRows / numPerPage);
        var $pager = $('<div class="pagination"></div>');

        for (var page = 0; page < numPages; page++) {
          $('<span class="page-number"></span>')
            .attr({
              id: `pagBtn-${page + 1}`,
            })
            .text(page + 1)
            .bind(
              'click',
              {
                newPage: page,
              },
              function (event) {
                sessionStorage.setItem('curPage', event.data['newPage']);
                currentPage = +sessionStorage.getItem(`curPage`);
                $table.trigger('repaginate');
                $(this).addClass('active').siblings().removeClass('active');
              },
            )
            .appendTo($pager)
            .addClass('clickable');
        }
        $pager.insertAfter($table);
        $('span.page-number')
          .filter(function () {
            return this.id === `pagBtn-${currentPage + 1}`;
          })
          .addClass('active');
      });
    });
  }

  getApi();

  setInterval(function () {
    $('.portfolio__table tbody tr, .pagination').remove();
    getApi();
  }, 9000);

  const storageTheme = localStorage.getItem('toggleTheme');

  if (storageTheme == 'true') {
    localStorage.setItem('toggleTheme', true);
    $('body').removeClass('light');
    $('.theme').removeClass('light');
    $('body').addClass('dark');
    $('.theme').addClass('dark');
  } else {
    localStorage.setItem('toggleTheme', false);
    $('body').removeClass('dark');
    $('.theme').removeClass('dark');
    $('body').addClass('light');
    $('.theme').addClass('light');
  }

  $('.theme').click(function () {
    const storageTheme = localStorage.getItem('toggleTheme');
    storageTheme == 'true' ? localStorage.setItem('toggleTheme', 'false') : localStorage.setItem('toggleTheme', 'true');

    if (localStorage.getItem('toggleTheme') == 'true') {
      $('body').removeClass('light');
      $('body').addClass('dark');
      $(this).removeClass('light');
      $(this).addClass('dark');
    } else {
      $('body').removeClass('dark');
      $('body').addClass('light');
      $(this).removeClass('dark');
      $(this).addClass('light');
    }

    return false;
  });
});
